<template>
  <div class="container">
    <div class="breadcrumb">
      <p class="topic_path m-0">
        <router-link :to="{ name: 'AccountSettings'}" class="ashen-link">{{ $t("account.default.pageTitle") }}</router-link>
        <i class="fas fa-angle-right form-control-color"></i>{{ $t("account.editWithoutPassword.hasCoronaVaccinationChange") }}
      </p>
    </div>
    <div class="card text-first border-0">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4 mt-sm-2">{{ $t("account.editWithoutPassword.hasCoronaVaccinationChange") }}</h3>
          <div class="col">
            <div class="form-group mt-3">
              <label>{{ $t("account.editWithoutPassword.hasCoronaVaccination") }}</label>
              <div class="row  mx-auto mt-0 mt-sm-4">
                <select v-model="coronaVaccinationCount" class="form-control form-select citizenship">
                  <option :value="0">{{ $t("account.editWithoutPassword.none")}}</option>
                  <option :value="1">{{ $t("account.editWithoutPassword.once")}}</option>
                  <option :value="2">{{ $t("account.editWithoutPassword.twice")}}</option>
                  <option :value="3">{{ $t("account.editWithoutPassword.thirdTime")}}</option>
                  <option :value="4">{{ $t("account.editWithoutPassword.fourthTime")}}</option>
                  <option :value="5">{{ $t("account.editWithoutPassword.fifthTime")}}</option>
                  <option :value="6">{{ $t("account.editWithoutPassword.sixthTime")}}</option>
                  <option :value="7">{{ $t("account.editWithoutPassword.seventhTime")}}</option>
                  <option :value="8">{{ $t("account.editWithoutPassword.eightthTime")}}</option>
                  <option :value="9">{{ $t("account.editWithoutPassword.ninethTime")}}</option>
                <option :value="10">{{ $t("account.editWithoutPassword.tenthTime")}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <label class="m-t-15 mb-2">
            {{ $t("account.editWithoutPassword.changeLatestCoronaVaccinationDate") }}
          </label>
          <div class="input-group mb-3">
            <Calender :disable="coronaVaccinationCount == 0"
                      :currentDate="latestCoronaVaccinationDate"
                      :type="'vaccination'"
                      @update="updateDate"
            ></Calender>
          </div>
        </div>
        <div v-show="errors['latestCoronaVaccinationDate']" id="err_lastInoculatinoDate" class="form-error">
          {{ errors['latestCoronaVaccinationDate'] }}
        </div>
        <div class="row m-t-5">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-1 order-sm-0">
            <button @click="$router.push('account')"
                    class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none mt-2 light border-0 custom-btn">{{ $t("account.editWithPassword.btnCancel") }}
            </button>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-2 custom-btn"
                    v-on:click="doChange">
              {{ $t("account.editWithPassword.btnChange") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SetUpdateUser} from "../assets/jsipclient/index";
import Common from "../assets/jsipclient/common";
import {mapGetters} from "vuex";
import Calender from "../components/layouts/Calender";

export default {
  name: "EditNoPasswordRequired",
  components: {Calender},
  data() {
    return {
      hasCoronaVaccination: '',
      coronaVaccinationCount: null,
      latestCoronaVaccinationDate: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = Number(this.coronaVaccinationCount) !=0 ? true : false
      params.coronaVaccinationCount = this.coronaVaccinationCount
      params.latestCoronaVaccinationDate = Common.dateFormatConvert(this.latestCoronaVaccinationDate)
      params.coronaVaccinationUpdate = true
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    getVaccineChangeDateFormat(e) {
      this.latestCoronaVaccinationDate = moment(e.target.value).format('YYYY/MM/DD');
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        if (setUpdateUser.hasCoronaVaccination == 0) {
          setUpdateUser.latestCoronaVaccinationDate = null
        }
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        const message = this.$t("account.editWithoutPassword.toastSuccessChangeHasCoronaVaccination")
        Common.showToast(message)
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        this.coronaVaccinationCount = jsonObject.coronaVaccinationCount
        this.hasCoronaVaccination = jsonObject.hasCoronaVaccination === true ? 1 : 0
        this.latestCoronaVaccinationDate = Common.dateFormatConvertForDatePicker(jsonObject.latestCoronaVaccinationDate)
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    updateDate(date) {
      this.latestCoronaVaccinationDate = date
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      await this.getData()
    }
  }
}
</script>

<style scoped>
.fa-angle-right {
  width: 15px;
  height: auto;
  color: #888888;
}

@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }

  .border-top {
    border: 0px !important;
  }
}

</style>
